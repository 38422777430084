<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 16:43:57
 * @LastEditTime: 2021-03-05 15:26:31
 * @FilePath: \acuconference-web\src\views\JoinMeeting.vue
-->
<template>
  <div class="join-meeting-wrapper">
    <div class="join-form-wrapper">
      <div class="title text-h4">
        {{ $t("label_tab_JoinMeeting") }}
      </div>
      <div class="join-inputer">
        <v-text-field
          class="center-input"
          :placeholder="$t('label_conference_invitation_code')"
          maxLength="500"
          v-model.trim="editCode"
          :disabled="loading"
           @blur="blurEditCode(editCode)"
          @keydown.enter="joinMeetingHandler"
        ></v-text-field>
      </div>
      <div class="join-btn">
        <v-btn
          :disabled="loading"
          :loading="loading"
          @click="joinMeetingHandler"
          rounded
          color="primary"
          block
          >{{ $t("label_tab_JoinMeeting") }}</v-btn
        >
      </div>
    </div>
    <StartMeeting
      @toggleLoadingStatus="toggleLoading"
      ref="startMeetingView"
      :value="false"
      :inviteCode="editCode"
    />
  </div>
</template>

<script>
import StartMeeting from "../components/startMeeting";
export default {
  components: { StartMeeting },
  props: ["inviteCode"],
  data() {
    return {
      editCode: "",
      joinMeeting: false,
      loading: false,
    };
  },
  created() {
    this.$nextTick(() => this.inviteCode && (this.editCode = this.inviteCode));
  },
  methods: {
    blurEditCode(val){
      this.editCode = val.replace(/\s+/g,'')
    },
    joinMeetingHandler() {
      if (this.editCode) {
        this.$refs.startMeetingView.checkRoomType();
      }
      // this.$bus.$login()
      // this.$login();
    },
    toggleLoading(val) {
      this.loading = val;
    },
  },
};
</script>

<style lang="sass" scoped>
.center-input
  input
    text-align: center

.join-meeting-wrapper
  position: relative
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  .join-form-wrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .join-btn
      width: 100%
    .title
      position: relative
      margin-bottom: 25px
</style>